* {
  font-family: "Rubik", "Helvetica", "sans-serif"
}

label {
  display: block;
  margin-top: 10px;
}
.card-container.card {
  max-width: 350px !important;
  padding: 40px 40px;
}
.card {
  background-color: #f7f7f7;
  padding: 20px 25px 30px;
  margin: 0 auto 25px;
  margin-top: 50px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}
.profile-img-card {
  width: 96px;
  height: 96px;
  margin: 0 auto 10px;
  display: block;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}

/* Style buttons */
.btn-full {
  border: none;
  color: white;
  padding: 12px 30px;
  cursor: pointer;
  font-size: 20px;
  height: 100%;
  width: 100%;
  margin: 10px;

}

/* Darker background on mouse-over */
.btn-full:hover {
  background-color: RoyalBlue;
}

.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.row-lock-height {
  margin-top: 10px;
  margin-bottom: 10px;
  min-height: 60px;
  max-height: 120px;
}

/* ============ DIVIDER FOR ALERT =========== */

/* The snackbar - position it at the bottom and in the middle of the screen */
#snackbar {
  visibility: hidden; /* Hidden by default. Visible on click */
  min-width: 250px; /* Set a default minimum width */
  margin-left: -125px; /* Divide value of min-width by 2 */
  background-color: #333; /* Black background color */
  color: #fff; /* White text color */
  text-align: center; /* Centered text */
  border-radius: 2px; /* Rounded borders */
  padding: 16px; /* Padding */
  position: fixed; /* Sit on top of the screen */
  z-index: 1; /* Add a z-index if needed */
  left: 50%; /* Center the snackbar */
  top: 10vh; /* 30px from the bottom */
}

/* Show the snackbar when clicking on a button (class added with JavaScript) */
#snackbar.show {
  visibility: visible; /* Show the snackbar */
  /* Add animation: Take 0.5 seconds to fade in and out the snackbar.
  However, delay the fade out process for 2.5 seconds */
  -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
  animation: fadein 0.5s, fadeout 0.5s 2.5s;
}

/* Animations to fade the snackbar in and out */
@-webkit-keyframes fadein {
  from {top: 0; opacity: 0;}
  to {top: 10vh; opacity: 1;}
}

@keyframes fadein {
  from {top: 0; opacity: 0;}
  to {top: 10vh; opacity: 1;}
}

@-webkit-keyframes fadeout {
  from {top: 10vh; opacity: 1;}
  to {top: 0; opacity: 0;}
}

@keyframes fadeout {
  from {top: 10vh; opacity: 1;}
  to {top: 0; opacity: 0;}
}

.notifications{
  width: 30vw;
  height: 40vh;
  border: solid 2px black;
  overflow: scroll;
  position: absolute;
  right: 50vw;
  top: 3.8rem;
  right: 0;
  bottom: 0;
  
}

.group-header-new{
  border-bottom: solid 1px black;
}

.group-header-earlier{
  border: solid 1px black;
}

.cell{
  display: flex;
  justify-content: space-between;
  margin: 1rem .5rem 1rem .5rem;
}

#bell-icon{
  position:absolute;
  right: 7vw;
}

@media only screen and (max-width: 992px) {
  #bell-icon{
    position:absolute;
    right: 8vw;
  }
}

@media only screen and (max-width: 1500px) {
  #bell-icon {
    position: absolute;
    right: 10vw;
  }
}

.ua-facebook-login-button {
  background-color: #1877F2;
  border-radius: 5px;
  padding: 10px;
  color: white;
  border-style: hidden;
}

.numberCircle {
  border-radius:50%;
  width:32px;
  height:32px;
  padding:6px;
  margin-right:10px;
  background:#002838;
  border:2px solid #355888;
  color:#FFF;
  text-align:center;
  vertical-align:middle;
  font:16px Arial, sans-serif;
  font-style:bold;
  display:inline-block
}

.lineClamp {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.listing-title {
  text-align: center;
  width: 16rem;
  font-size: 1.0em;
}

.text-sea-green {
  color: #06A;
  font-weight: bold;
}

.right { 
  float: right;
}

.bid_history {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow-y: hidden;
  height: 350px;
}

.bid_history > li {
    color: black;
    padding: 5px;
}

.bid_history > li:nth-child(even) {
    background-color: #FFF;
}

.bid_history > li:nth-child(odd) {
    background-color: #DDD;
}

.bid_history > li:first-child {
    background-color: #f9c20c;
    color: black;
    font-weight: bold;
}

.bid_history > li:nth-child(2) {
  background-color: #def;
}

.bid_history > li:nth-child(3) {
  background-color: #f96;
  color: white;
}

.badge-alt {
  background-color: #13195f;
  color: white;
  padding: 8px 8px 8px 8px;
  margin-left: 8px;
  border-radius: 16px;
}

.button-mobile-fixed {
	position:fixed;
	height:60px;
  padding-left: 16px;
  padding-right: 16px;
  right: 14px;
	background-color:#13195f;
	color:#FFF;
	border-radius:50px;
	text-align:center;
	box-shadow: 0px 3px 5px #3339;
}

.button-mobile-fixed-content {
	margin-top: 19px;
  font-size: 18px;
}

.btn-fb-fit {
  width: 95%;
}

.h4-responsive {
  padding-left: 10px;
  padding-right: 10px;
}

.mimic-link {
  color: #004499 !important;
}

.spacer-64 {
  height: 64px;
  width: 100%;
}

.nav-footer {
  display: block;
  position: fixed;
  bottom: 0;
  height: 64px;
  width: 100%;
  box-shadow: 0px -3px 5px #6664;
  background-color: white;
}

.nav-flex-footer {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: center;
  height: 100%;
  width: 100%;
  background-color: white;
  align-items: center;
}

.nav-flex-footer .item {
  flex-grow: 1;
  color: black;
  text-align: center;
}